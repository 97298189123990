<!--
 *@Description: 
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-07-13 15:53:26
-->
<template>
	<div class="app-container">
		<el-row :gutter="20">
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>报修设备信息</span>
					</div>
					<div class="repairDerailStep">
						<div style="
								width: 100%;
								display: flex;
								justify-content: center;
							">
							<el-image style="
									width: 120px;
									height: 120px;
									border-radius: 50%;
								" v-if="repairInfo.devInfo.devInfoImgUrl" class="p10b1" :src="imgUrl +
									'devbase/' +
									repairInfo.devInfo.devInfoImgUrl
									" :preview-src-list="[
										imgUrl +
										'devbase/' +
										repairInfo.devInfo.devInfoImgUrl
									]" :fit="'contain'">
								<div slot="error" class="image-slot">
									暂无图片
								</div>
							</el-image>
						</div>
						<ul>
							<li>
								<span class="title">设备名称</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoName }}
								</span>
							</li>
							<li>
								<span class="title">种类</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoTypeName }}
								</span>
							</li>
							<li>
								<span class="title">型号</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoNns1 }}
								</span>
							</li>
							<li>
								<span class="title">科室</span>
								<span class="content">
									{{
										repairInfo.devInfo.devInfoDepartmentName
									}}
								</span>
							</li>
							<li>
								<!-- <span class="title">院内编号</span> -->
								<!-- <span class="content">{{ repairInfo.devInfo.hospitalAssetCode }}</span> -->
								<span class="title">设备编码</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoNumber }}
								</span>
							</li>
							<li>
								<span class="title">出厂序列号</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoSerialNo }}
								</span>
							</li>
							<li>
								<span class="title">维护公司</span>
								<span class="content">
									{{ repairInfo.devInfo.orgName }}
								</span>
							</li>
							<li>
								<span class="title">生产厂家</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoFactoryName }}
								</span>
							</li>
							<li>
								<span class="title">采购日期</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoEnableTime }}
								</span>
							</li>
							<li>
								<span class="title">设备价格</span>
								<span class="content">
									{{ repairInfo.devInfo.devInfoNns3 }}
								</span>
							</li>
						</ul>
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>报修信息</span>
					</div>
					<div class="repairDerailStep">
						<ul>
							<li>
								<span class="title">报修单</span>
								<span class="content">
									{{ repairInfo.code }}
								</span>
							</li>
							<li>
								<span class="title">状态</span>
								<span class="content">
									<span class="equip_state" v-if="
										Number(repairInfo.actionState) === 0
									">
										待接单
									</span>
									<span class="equip_state equip_state1" v-else-if="
										Number(repairInfo.actionState) === 1
									">
										已响应
									</span>
									<span class="equip_state equip_state2" v-else-if="
										Number(repairInfo.actionState) === 2
									">
										已到场
									</span>
									<span class="equip_state equip_state3" v-else-if="
										Number(repairInfo.actionState) === 3
									">
										维修中
									</span>
									<span class="equip_state equip_state4" v-else-if="
										Number(repairInfo.actionState) === 4
									">
										待评价
									</span>
									<span class="equip_state equip_state5" v-else-if="
										Number(repairInfo.actionState) === 5
									">
										已完成
									</span>
									<span v-else></span>
								</span>
							</li>
							<li>
								<span class="title">报修医院</span>
								<span class="content">
									{{
										repairInfo.devInfo
											.affiliatedHospitalName
									}}
								</span>
							</li>
							<li>
								<span class="title">报修部门</span>
								<span class="content">
									{{
										repairInfo.devInfo.devInfoDepartmentName
									}}
								</span>
							</li>
							<li>
								<span class="title">报修人</span>
								<span class="content">
									{{ repairInfo.repairUserName }}
								</span>
							</li>
							<li>
								<span class="title">报修日期</span>
								<span class="content">
									{{ repairInfo.repairTime }}
								</span>
							</li>
							<li v-if="repairInfo.createUserName">
								<span class="title">录入人</span>
								<span class="content">
									{{ repairInfo.createUserName }}
								</span>
							</li>

							<li v-if="repairInfo.createUserName">
								<span class="title">纸质工单</span>
								<span class="content" style="display: flex; align-items: center; justify-content: end">

									<template v-if="organizationType == 2">
										<template v-if="repairInfo.isEditPaper">
											<input v-model="repairInfo.paperNO" style="width:90px; padding-left:10px; padding-right:10px;" /><el-button
												@click="updatepaper({ code: repairInfo.paperNO, repairid: repairInfo.devRepairId })">保存</el-button>
										</template>
										<template v-else>
											{{ repairInfo.paperNO || '无' }}
											<i v-if="repairInfo.paperNO" @click="isShowEditPaper" class="el-icon-edit"
												style="color: #f56c6c; cursor: pointer; padding-left:4px;"></i>
											<i v-else @click="isShowEditPaper" class="el-icon-circle-plus-outline"
												style="color: #f56c6c; cursor: pointer; padding-left:4px;"></i>
										</template>
									</template>
									<template v-else>{{ repairInfo.paperNO || '无' }}</template>
								</span>
							</li>
						</ul>
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix" style="display: flex">
						<span>维修进度</span>
						<p style="flex: 1"></p>
						<template v-if="organizationType == 2">
							<el-button type="primary" size="mini" @click="handleAddRepairProcessInfo" v-if="
								Number(repairInfo.actionState) == 2 ||
								Number(repairInfo.actionState) == 3
							">
								增加维修过程
							</el-button>
						</template>
					</div>

					<el-steps direction="vertical" :active="repairInfo.actionState + 1" finish-status="success"
						style="margin-left: 0px">
						<el-step title="报修">
							<div slot="description">
								<ul>
									<li>
										报修日期:{{ repairInfo.repairTime }}
										<i style="
												color: #f56c6c;
												cursor: pointer;
											" class="el-icon-edit" v-if="organizationType == 2" @click="
												showProcessTimeDialog({
													processid: 0,
													timetype: 0,
													time: repairInfo.repairTime
												})
												"></i>
									</li>
									<li>
										报修描述:{{
											repairInfo.faultDescription
										}}
									</li>
									<template v-if="repairInfo.faultImgUrl">
										<li>故障照片:</li>
										<li v-if="
											repairInfo.faultImgUrls.length >
											0
										">
											<p>
												<el-image class="p5b1" style="
														width: 118px;
														height: 118px;
														margin-right: 10px;
													" v-for="(item, index) in [
														...repairInfo.faultImgUrls
													]" :src="imgUrl +
														'devrepairInfoservice/' +
														item
														" :preview-src-list="[
															imgUrl +
															'devrepairInfoservice/' +
															repairInfo.faultImgUrls
														]" :fit="'contain'" :key="index">
													<div slot="error" class="image-slot">
														暂无图片
													</div>
												</el-image>
											</p>
										</li>
									</template>
								</ul>
							</div>
						</el-step>
						<el-step title="响应" v-if="repairInfo.actionState >= 1">
							<div slot="description">
								<p>
									{{ repairInfo.undertakeTime || '' }}

									<i style="color: #f56c6c; cursor: pointer" class="el-icon-edit"
										v-if="organizationType == 2" @click="
											showProcessTimeDialog({
												processid: 0,
												timetype: 1,
												time: repairInfo.undertakeTime
											})
											"></i>
								</p>
								<p>
									工程师{{
										repairInfo.listpoNew[0].engineerName ||
										repairInfo.engineerName ||
										''
									}}已接单
								</p>
							</div>
						</el-step>
						<el-step title="已到场" v-if="repairInfo.actionState >= 2">
							<div slot="description">
								<i v-if="organizationType == 2" class="el-icon-edit"
									style="color: #f56c6c; cursor: pointer" @click="
										editRepairProgressS(
											repairInfo.listpoNew[1]
										)
										">
									编辑
								</i>
								<p>
									{{
										repairInfo.listpoNew[1].actionTime || ''
									}}
									<i style="color: #f56c6c; cursor: pointer" class="el-icon-edit"
										v-if="organizationType == 2" @click="
											showProcessTimeDialog({
												processid:
													repairInfo.listpoNew[1]
														.repairProcessId,
												timetype: 2,
												time: repairInfo.listpoNew[1]
													.actionTime
											})
											"></i>
								</p>
								<p>
									工程师{{
										repairInfo.listpoNew[1].engineerName ||
										repairInfo.engineerName ||
										''
									}}已到达现场
								</p>
								<p>
									{{
										repairInfo.listpoNew[1]
											.actionDescribe || ''
									}}
								</p>
								<p v-if="repairInfo.listpoNew[1].repairImgUrl">
									<el-image class="p5b1" style="width: 118px; height: 118px" :src="imgUrl +
										'repairprocessInfoservice/' +
										repairInfo.listpoNew[1].repairImgUrl
										" :preview-src-list="[
											imgUrl +
											'repairprocessInfoservice/' +
											repairInfo.listpoNew[1]
												.repairImgUrl
										]" :fit="'contain'">
										<div slot="error" class="image-slot">
											暂无图片
										</div>
									</el-image>
								</p>
							</div>
						</el-step>
						<el-step title="维修中" v-if="repairInfo.actionState >= 3">
							<div slot="description">
								<!-- <p>{{repairInfo.listpoNew[2].actionTime || ''}}</p> -->
								<div v-for="(
										item, index
									) in repairInfo.listType3" :key="index">
									<p>
										<i v-if="organizationType == 2" class="el-icon-edit" style="
												color: #f56c6c;
												cursor: pointer;
											" @click="editRepairProgressS(item)">
											编辑
										</i>
									</p>
									<b>
										{{ index + 1 }}、工程师{{
											item.engineerName || ''
										}}维修中：
									</b>
									<br />
									{{ item.actionTime }}
									<i style="color: #f56c6c; cursor: pointer" class="el-icon-edit"
										v-if="organizationType == 2" @click="
											showProcessTimeDialog({
												processid: item.repairProcessId,
												timetype: 3,
												time: item.actionTime
											})
											"></i>

									<br />
									{{ item.title }}
									<br />
									{{ item.actionDescribe }}
									<br />
									<p v-if="item.repairImgUrlArr.length > 0">
										<el-image v-for="(
												item1, index1
											) in item.repairImgUrlArr" :key="index1" class="p5b1" style="
												width: 118px;
												height: 118px;
												margin-right: 10px;
											" :src="imgUrl +
												'repairprocessInfoservice/' +
												item1
												" :preview-src-list="[
													imgUrl +
													'repairprocessInfoservice/' +
													item1
												]" :fit="'contain'">
											<div slot="error" class="image-slot">
												暂无图片
											</div>
										</el-image>
									</p>
								</div>
								<!-- <p>
									<el-image class="p5b1" style="width: 118px; height: 118px" :src="
										imgUrl +
										'repairprocessInfoservice/' +
										repairInfo.listpoNew[1].repairImgUrl
									" :preview-src-list="[
										imgUrl +
										'repairprocessInfoservice/' +
																							repairInfo.listpoNew[1].repairImgUrl
																						]" :fit="'contain'">
																							<div slot="error" class="image-slot">暂无图片</div>
																						</el-image>
																					</p> -->
							</div>
						</el-step>
						<el-step title="维修完成" v-if="repairInfo.actionState >= 4">
							<div slot="description">
								<i v-if="organizationType == 2" class="el-icon-edit"
									style="color: #f56c6c; cursor: pointer" @click="
										editRepairProgressS(
											repairInfo.listpoNew[2]
										)
										">
									编辑
								</i>
								<p>
									{{ repairInfo.actionCompleteTime }}

									<i style="color: #f56c6c; cursor: pointer" class="el-icon-edit"
										v-if="organizationType == 2" @click="
											showProcessTimeDialog({
												processid: 0,
												timetype: 4,
												time: repairInfo.actionCompleteTime
											})
											"></i>
								</p>
								<p>
									工程师{{
										repairInfo.listpoNew[2].engineerName ||
										''
									}}维修完成
								</p>
								<p>
									{{
										repairInfo.listpoNew[2]
											.actionDescribe || ''
									}}
								</p>
								<!-- <p v-for="(item,index) in repairInfo.listpoNew[2].repairImgUrlArr" :key="index"> -->
								<template v-if="
									repairInfo.listpoNew[2].repairImgUrlArr
										.length > 0
								">
									<el-image v-for="(item, index) in repairInfo
										.listpoNew[2].repairImgUrlArr" :key="index" class="p5b1" style="
											width: 118px;
											height: 118px;
											margin-right: 10px;
										" :src="imgUrl +
											'repairprocessInfoservice/' +
											item
											" :preview-src-list="[
												imgUrl +
												'repairprocessInfoservice/' +
												item
											]" :fit="'contain'" lazy>
										<div slot="error" class="image-slot">
											暂无图片
										</div>
									</el-image>
								</template>
								<!-- </p> -->
							</div>
						</el-step>
						<el-step title="评价" v-if="repairInfo.actionState >= 5">
							<div slot="description">
								<i class="el-icon-star-on yellow f14" v-for="(item, index) in Number(
									repairInfo.repairEvaluationLv
								)" :key="index"></i>
							</div>
						</el-step>
					</el-steps>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>维修费用应收</span>
						<span style="float: right; margin-top: -3px">
							<el-button @click="addRepairMoneyAboutHandler" v-if="organizationType == 2">
								关联
							</el-button>
							<el-button @click="addRepairMoneyHandler">
								添加
							</el-button>
						</span>
					</div>
					<div class="money-box">
						<el-row class="item" v-for="(item, index) in repairMoneyData" :key="index">
							<el-col :span="12">{{ item.title }}</el-col>
							<el-col :span="6">{{ item.price }}</el-col>
							<el-col :span="6">
								<el-link type="primary" @click="repariMoneyDetail(item)" style="margin-right: 4px">
									编辑
								</el-link>
								<el-link type="primary" @click="deleteHandler(item)">
									删除
								</el-link>
							</el-col>
						</el-row>
						<el-row>
							<div style="text-align: right">
								合计：{{ totalMoney }}
							</div>
						</el-row>
					</div>
				</el-card>
				<el-card class="box-card" shadow="hover" style="margin-top: 20px" v-if="true">
					<div slot="header" class="clearfix">
						<span>维修费用实收</span>
						<span style="float: right; margin-top: -3px">
							<el-button @click="addRepairRealCostHandler">
								{{ repairInfo.realprice ? '修改' : '设置' }}
							</el-button>
						</span>
					</div>
					<div class="money-box">
						<el-form :model="costRealForm" :rules="rulesRealCost" ref="costRealForm" label-width="0">
							<el-row class="item" style="line-height: 30px">
								<el-col :span="24">
									金额：
									<template v-if="isEditRealCost">
										<el-form-item label="" prop="realprice">
											<el-input v-model="costRealForm.realprice" type="number"></el-input>
										</el-form-item>
									</template>
									<template v-else>
										{{
											repairInfo.realprice == 0
												? 0
												: repairInfo.realprice || '-'
										}}
									</template>
								</el-col>
								<el-col :span="24">
									说明：
									<template v-if="isEditRealCost">
										<el-form-item label="">
											<el-input type="textarea" v-model="costRealForm.realpricememo
												" :rows="6"></el-input>
										</el-form-item>
									</template>
									<template v-else>
										{{ repairInfo.realpricememo || '-' }}
									</template>
								</el-col>
								<el-col :span="24" style="text-align: right; margin-top: 20px" v-if="isEditRealCost">
									<el-button @click="
										submitRepairRealCostHandler(
											'costRealForm'
										)
										">
										保存
									</el-button>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</el-card>
				<el-card class="box-card" shadow="hover" style="margin-top: 20px" v-if="organizationType == 2">
					<div slot="header" class="clearfix">
						<span>设置成本</span>
						<span style="float: right; margin-top: -3px">
							<el-button @click="addRepairCostAboutHandler" v-if="organizationType == 2">
								关联
							</el-button>
							<el-button @click="addRepairCostHandler">
								{{ repairInfo.cost ? '修改成本' : '设置成本' }}
							</el-button>
						</span>
					</div>
					<div class="money-box">
						<el-form :model="costForm" :rules="rulesCost" ref="costForm" label-width="0">
							<el-row class="item" style="line-height: 30px">
								<el-col :span="24">
									金额：
									<template v-if="isEditCost">
										<el-form-item label="" prop="cost">
											<el-input v-model="costForm.cost" type="number"></el-input>
										</el-form-item>
									</template>
									<template v-else>
										{{
											repairInfo.cost == 0
												? 0
												: repairInfo.cost || '-'
										}}
									</template>
								</el-col>
								<el-col :span="24">
									说明：
									<template v-if="isEditCost">
										<el-form-item label="" prop="costinfo">
											<el-input type="textarea" v-model="costForm.costinfo" :rows="6"></el-input>
										</el-form-item>
									</template>
									<template v-else>
										<pre>{{
											repairInfo.costinfo || '-'
										}}</pre>
									</template>
								</el-col>
								<el-col :span="24" style="text-align: right; margin-top: 20px" v-if="isEditCost">
									<el-button @click="
										submitRepairCostHandler('costForm')
										">
										保存
									</el-button>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</el-card>

				<el-card class="box-card" shadow="hover" style="margin-top: 20px" v-if="organizationType == 2">
					<div slot="header" class="clearfix">
						<span>设置工作量</span>
					</div>
					<div class="money-box">
						<el-form :model="percentsForm" label-width="0">
							<el-row class="item" style="line-height: 30px">
								<el-col :span="24" v-for="(
										item2, index2
									) in percentsForm.percents" :key="index2">
									{{ item2.username }}
									<el-slider v-model="item2.pct" :step="10"></el-slider>
								</el-col>
								<el-col :span="24" style="text-align: right; margin-top: 20px">
									<el-button @click="submitRepairPercentsHandler">
										保存
									</el-button>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog :visible.sync="isShowRepairMoney" title="添加维修费用">
			<el-form :model="formData" :rules="formRule" ref="formEle" label-width="120px" class="demo-ruleForm">
				<el-form-item label="配件名称" prop="name">
					<el-input v-model="formData.name"></el-input>
				</el-form-item>
				<el-form-item label="配件价格" prop="money">
					<el-input v-model="formData.money" @input="setsingleprice"></el-input>
				</el-form-item>
				<el-form-item label="实物" prop="isHave">
					<el-radio-group v-model="formData.isHave" @input="getIsHave">
						<el-radio-button label="1">是</el-radio-button>
						<el-radio-button label="0">否</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="配件品牌型号" prop="goodsinfo">
					<el-input v-model="formData.goodsinfo" :disabled="formData.isHave == 0"></el-input>
				</el-form-item>
				<el-form-item label="配件单价" prop="singleprice">
					<el-input v-model="formData.singleprice" :disabled="formData.isHave == 0"></el-input>
				</el-form-item>
				<el-form-item label="配件数量" prop="goodscnt">
					<el-input v-model="formData.goodscnt" type="number" step="1" :disabled="formData.isHave == 0"
						onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
				</el-form-item>
				<el-form-item>
					<div style="text-align: right">
						<el-button @click="cancelHandler">取消</el-button>
						<el-button type="primary" v-if="repairMoneyEditId === ''" @click.native="addRepairMoney">
							确定
						</el-button>
						<el-button type="primary" v-if="repairMoneyEditId !== ''" @click.native="editRepairMoney">
							确定
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</Dialog>
		<!-- 销售列表  -->
		<Dialog title="销售列表" :visible.sync="isShowRepairMoney1" width="88%" custom-class="dialogDoc dialogCom3"
			:modal-append-to-body="false">
			<div class="dialogDocSearch" style="display: none">
				<el-form class="form" ref="searchFormP" :model="searchFormP" label-width="70px" @submit.native.prevent
					@keyup.enter.native="saleApplicationList">
					<el-form-item label="申请单号">
						<el-input v-model="searchFormS.code" placeholder="申请单号" />
					</el-form-item>
					<el-form-item label="" label-width="0" style="margin-left: 10px">
						<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
							@click="saleApplicationList">
							搜索
						</el-button>
						<!-- <el-button
							class="filter-item"
							size="mini"
							icon="el-icon-refresh-left"
							@click="resetFormGood('searchFormP')"
						>
							重置
						</el-button> -->
					</el-form-item>
				</el-form>
			</div>
			<div style="width: 100%; height: 10px"></div>

			<Table ref="tableDom" :data.sync="saleTableData" :row-class-name="tableRowClassName">
				<el-table-column label="序号" width="140">
					<template slot-scope="scope">
						<p style="font-size: 14px; font-weight: bold">
							{{ scope.row.id }}
						</p>
						<p style="font-size: 12px">
							创建:{{
								scope.row.createDate | parseTime('{y}-{m}-{d}')
							}}
						</p>
						<p v-if="scope.row.lastUpdateDate" style="font-size: 12px">
							更新:{{
								scope.row.lastUpdateDate
								| parseTime('{y}-{m}-{d}')
							}}
						</p>
					</template>
				</el-table-column>
				<el-table-column label="申请单号" width="180">
					<template slot-scope="scope">
						<p style="font-size: 14px">{{ scope.row.code }}</p>
						<p style="font-size: 12px">
							申请:{{ scope.row.requestTime | parseTime }}
						</p>
						<p style="font-size: 12px">
							申请人:{{ scope.row.creatorName }}
						</p>
					</template>
				</el-table-column>

				<el-table-column label="库房" width="200">
					<template slot-scope="scope">
						<p style="font-size: 14px">
							<b>{{ scope.row.warehouseName }}</b>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="销售数量" width="100">
					<template slot-scope="scope">
						<p style="font-size: 14px">
							<span v-if="scope.row.doneCnt == scope.row.sumCnt" style="color: #67c23a; cursor: pointer">
								<span title="已出库">
									{{ scope.row.doneCnt }}
								</span>
								/
								<span title="物料总数">
									{{ scope.row.sumCnt }}
								</span>
							</span>
							<span v-else style="color: #e6a23c; cursor: pointer">
								<span title="已出库">
									{{ scope.row.doneCnt }}
								</span>
								/
								<span title="物料总数">
									{{ scope.row.sumCnt }}
								</span>
							</span>
						</p>
						<p style="font-size: 12px">
							金额:{{ scope.row.sumPrice }}
						</p>
					</template>
				</el-table-column>
				<el-table-column label="相关物料" min-width="350">
					<template slot-scope="scope">
						<p @click="showMoreMaterial(scope.row)" v-if="scope.row.items.length > 3">
							<el-link type="primary">更多</el-link>
						</p>
						<p style="font-size: 12px" v-for="(item1, index1) in scope.row.items" :key="item1.id">
							<template v-if="index1 < scope.row.showMaterialLength">
								<b>{{ index1 + 1 }}</b>
								、{{ item1.goodsName }}：
								<b>{{ item1.price }}</b>
								元 ×
								<b>{{ item1.cnt }}</b>
								个
							</template>
						</p>
					</template>
				</el-table-column>

				<el-table-column label="状态记录" width="150">
					<template slot-scope="scope">
						<p style="font-size: 12px">
							<span style="
									width: 0.8rem;
									display: inline-block;
									text-align: right;
								">
								是否开票:
							</span>
							<el-switch v-model="scope.row.kaipiao" @change="setKaipiao($event, scope.row)"></el-switch>
						</p>
						<p style="font-size: 12px">
							<span style="
									width: 0.8rem;
									display: inline-block;
									text-align: right;
								">
								是否付款:
							</span>
							<el-switch v-model="scope.row.fukuan" @change="setFukuan($event, scope.row)"></el-switch>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="状态" width="130">
					<template slot-scope="scope">
						<span v-if="scope.row.state === 0">未审核</span>
						<span v-else-if="scope.row.state === 1">通过</span>
						<span v-else-if="scope.row.state === 2" style="color: #f56c6c">
							不通过
						</span>
						<span v-else style="color: #67c23a">已执行</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" align="left">
					<template slot-scope="scope">
						<el-button id="btn-update-row" type="primary" @click="toGoodChooseS(scope.row)">
							选择
						</el-button>
					</template>
				</el-table-column>
			</Table>

			<Pagination :total="totalS" :pageIndex_p.sync="pageIndexS" :pageSize_p.sync="pageSizeS"></Pagination>
		</Dialog>

		<!-- 采购列表 -->
		<Dialog title="采购列表" :visible.sync="isEditCost1" width="88%" custom-class="dialogDoc dialogCom3"
			:modal-append-to-body="false">
			<div class="dialogDocSearch" style="display: none">
				<el-form class="form" ref="searchFormP" :model="searchFormP" label-width="70px" @submit.native.prevent
					@keyup.enter.native="purchaseApplicationList">
					<el-form-item label="申请单号">
						<el-input v-model="searchFormP.code" placeholder="申请单号" />
					</el-form-item>
					<el-form-item label="" label-width="0" style="margin-left: 10px">
						<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
							@click="purchaseApplicationList">
							搜索
						</el-button>
						<!-- <el-button
							class="filter-item"
							size="mini"
							icon="el-icon-refresh-left"
							@click="resetFormGood('searchFormP')"
						>
							重置
						</el-button> -->
					</el-form-item>
				</el-form>
			</div>
			<div style="width: 100%; height: 10px"></div>
			<!--表格渲染-->
			<Table ref="tableDom" :data.sync="purchaseAppTableData" :row-class-name="tableRowClassName"
				isHideCheckbox_p="false">
				<el-table-column label="序号" width="140">
					<template slot-scope="scope">
						<p style="font-size: 14px; font-weight: bold">
							{{ scope.row.id }}
						</p>
						<p style="font-size: 12px">
							创建:{{
								scope.row.createDate | parseTime('{y}-{m}-{d}')
							}}
						</p>
						<p v-if="scope.row.lastUpdateDate" style="font-size: 12px">
							更新:{{
								scope.row.lastUpdateDate
								| parseTime('{y}-{m}-{d}')
							}}
						</p>
					</template>
				</el-table-column>
				<el-table-column label="申请单号" width="180">
					<template slot-scope="scope">
						<p style="font-size: 14px">{{ scope.row.code }}</p>
						<p style="font-size: 12px">
							申请:{{ scope.row.requestTime | parseTime }}
						</p>
						<p style="font-size: 12px">
							申请人:{{ scope.row.creatorName }}
						</p>
					</template>
				</el-table-column>
				<el-table-column label="库房" width="200">
					<template slot-scope="scope">
						<p style="font-size: 14px">
							<b>{{ scope.row.warehouseName }}</b>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="采购数量" width="100">
					<template slot-scope="scope">
						<p style="font-size: 14px">
							<span v-if="scope.row.doneCnt == scope.row.sumCnt" style="color: #67c23a; cursor: pointer">
								<span title="已入库">
									{{ scope.row.doneCnt }}
								</span>
								/
								<span title="物料总数">
									{{ scope.row.sumCnt }}
								</span>
							</span>
							<span v-else style="color: #e6a23c; cursor: pointer">
								<span title="已入库">
									{{ scope.row.doneCnt }}
								</span>
								/
								<span title="物料总数">
									{{ scope.row.sumCnt }}
								</span>
							</span>
						</p>
						<p style="font-size: 12px">
							金额:{{ scope.row.sumPrice }}
						</p>
					</template>
				</el-table-column>

				<el-table-column label="相关物料" min-width="350">
					<template slot-scope="scope">
						<p @click="showMoreMaterialP(scope.row)" v-if="scope.row.items.length > 3">
							<el-link type="primary">更多</el-link>
						</p>
						<p v-for="(item1, index1) in scope.row.items" :key="item1.id">
							<template v-if="index1 < scope.row.showMaterialLength">
								<b>{{ index1 + 1 }}</b>
								、{{ item1.goodsName }}：
								<b>{{ item1.price }}</b>
								元 ×
								<b>{{ item1.cnt }}</b>
								个
							</template>
						</p>
					</template>
				</el-table-column>

				<el-table-column label="状态记录" width="150">
					<template slot-scope="scope">
						<p style="font-size: 12px">
							<span style="
									width: 0.8rem;
									display: inline-block;
									text-align: right;
								">
								是否开票:
							</span>
							<el-switch v-model="scope.row.kaipiao" @change="setKaipiao($event, scope.row)"></el-switch>
						</p>
						<p style="font-size: 12px">
							<span style="
									width: 0.8rem;
									display: inline-block;
									text-align: right;
								">
								是否付款:
							</span>
							<el-switch v-model="scope.row.fukuan" @change="setFukuan($event, scope.row)"></el-switch>
						</p>
						<p style="font-size: 12px">
							<span style="
									width: 0.8rem;
									display: inline-block;
									text-align: right;
								">
								是否申请调拨:
							</span>
							<el-switch v-model="scope.row.doDB" @change="setDB($event, scope.row)"></el-switch>
						</p>
						<p style="font-size: 12px">
							<span style="
									width: 0.8rem;
									display: inline-block;
									text-align: right;
								">
								是否申请销售:
							</span>
							<el-switch v-model="scope.row.doSell" @change="setSell($event, scope.row)"></el-switch>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="状态" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.state === 0">未审核</span>
						<span v-else-if="scope.row.state === 1">通过</span>
						<span v-else-if="scope.row.state === 2" style="color: #f56c6c">
							不通过
						</span>
						<span v-else style="color: #67c23a">已执行</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="left">
					<template slot-scope="scope">
						<el-button id="btn-update-row" type="primary" @click="toGoodChooseP(scope.row)">
							选择
						</el-button>
					</template>
				</el-table-column>
			</Table>

			<Pagination :total="totalP" :pageIndex_p.sync="pageIndexP" :pageSize_p.sync="pageSizeP"></Pagination>
		</Dialog>

		<!-- 增加维修过程 -->
		<Dialog :visible.sync="isShowProcessDialog" title="增加维修过程">
			<el-form :model="formProcessData" :rules="formProcessRule" ref="formAddProcessRef" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="设备相关证照" style="
						float: none;
						min-height: 125px;
						height: auto;
						width: 100%;
					">
					<Upload :action="uploadLogoAction" list-type="picture-card" :file-list="extFile"
						:on-success="handlerExtSuccess" :on-remove="handlerExtRemove" :isShowTip_p="false"></Upload>
				</el-form-item>
				<el-form-item label="标题" prop="title">
					<el-input v-model="formProcessData.title"></el-input>
				</el-form-item>
				<el-form-item label="描述" prop="actionDescribe">
					<el-input v-model="formProcessData.actionDescribe"></el-input>
				</el-form-item>
				<el-form-item>
					<div style="text-align: right">
						<el-button @click="cancelHandlerRepairProcessInfo">
							取消
						</el-button>
						<el-button type="primary" @click.native="addRepairProcessInfo">
							确定
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</Dialog>

		<!-- 编辑已到场维修过程 -->
		<Dialog :visible.sync="isShowRepairProgressS" title="编辑" width="600px">
			<el-form :model="repairProgressForm" :rules="repairProgressRule" ref="formProcessRef" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="照片" style="
						float: none;
						min-height: 125px;
						height: auto;
						width: 100%;
					">
					<Upload :action="uploadLogoAction" list-type="picture-card"
						:file-list="repairProgressForm.repairImgUrlArr" :on-success="handlerExtSuccessS"
						:on-remove="handlerExtRemoveS" :isShowTip_p="false"
						:limit="repairProgressForm.actionState == 2 ? 1 : 10"></Upload>
				</el-form-item>
				<el-form-item label="标题" prop="title" v-if="repairProgressForm.actionState == 3">
					<el-input v-model="repairProgressForm.title"></el-input>
				</el-form-item>
				<el-form-item label="描述" prop="actionDescribe">
					<el-input v-model="repairProgressForm.actionDescribe"></el-input>
				</el-form-item>
				<el-form-item>
					<div style="text-align: right">
						<el-button @click="cancelHandlerRepairProcessSIF">
							取消
						</el-button>
						<el-button type="primary" @click.native="addRepairProcessInfoS">
							确定
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</Dialog>

		<!--  修改时间 维修过程 -->
		<Dialog :visible.sync="isShowProcessTimeDialog" title="修改时间">
			<el-form :model="processTimeObj" :rules="processTimeObjRule" ref="processTimeObjRef" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="时间" prop="time">
					<el-date-picker v-model="processTimeObj.time" type="datetime" placeholder="选择日期时间"
						value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<div style="text-align: right">
						<el-button @click="cancelHandlerRepairProcessTime">
							取消
						</el-button>
						<el-button type="primary" @click.native="bindProcessTimeChange">
							确定
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	queryDevRepairInfoByIdProcessService,
	repairMoneyListService,
	repairMoneyAddService,
	repairMoneyDelService,
	repairMoneyDetailService,
	repairMoneyUpdateService,
	repairSetCostService,
	setRepairrealPriceService,
	repairSetPercentsService,
	updatewmsoutidService,
	updatewmsinidService,
	addRepairProcessInfoService,
	updaterepairtime1Service,
	updateRepairProcessInfoService,
	updatepaperService
} from '@s/repair/RepairService';
// eslint-disable-next-line import/no-cycle
import { saleApplicationListService } from '@s/spareParts/SaleApplicationService';
// eslint-disable-next-line import/no-cycle
import { purchaseApplicationListService } from '@s/spareParts/PurchaseApplicationService';
import Dialog from '@c/ui/Dialog';
import Table from '@c/ui/Table';
import Upload from '@c/ui/Upload.vue';
import Pagination from '@c/ui/Pagination';
import { hgetStorage } from '../../util/htools.web';

export default {
	name: '',
	components: {
		Dialog,
		Table,
		Pagination,
		Upload
	},
	data() {
		const moneyValid = (rule, value, callback) => {
			const reg =
				/(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
			if (!reg.test(value)) {
				callback(new Error('请输入正确的费用'));
			} else {
				callback();
			}
		};
		return {
			repairProgressForm: {},
			repairProgressRule: {
				title: [
					{ required: true, message: '请输入标题', trigger: 'blur' }
				],
				actionDescribe: [
					{ required: true, message: '请输入描述', trigger: 'blur' }
				]
			},
			isShowRepairProgressS: false,
			isShowRepairProgressI: false,
			isShowRepairProgressF: false,

			isShowProcessTimeDialog: false,
			processTimeObj: {},

			processTimeObjRule: {
				time: [
					{ required: true, message: '请选择时间', trigger: 'change' }
				]
			},
			repairInfo: { devInfo: {} },
			repairInfoId: null,
			imgUrl: null,
			repairMoneyData: [],
			isShowRepairMoney: false,
			// 维修费用 关联 销售
			isShowRepairMoney1: false,
			saleTableData: [],
			pageIndexS: 1,
			pageSizeS: 10,
			totalS: 0,
			searchFormS: {},
			delId: '',
			repairMoneyEditId: '',
			formData: {
				name: '',
				money: '',
				isHave: '1',
				goodsinfo: '',
				singleprice: '',
				goodscnt: ''
			},
			formRule: {
				name: [
					{
						required: true,
						message: '请输入配件名称',
						trigger: 'blur'
					}
				],
				money: [
					{
						required: true,
						message: '请输入配件价格',
						trigger: 'blur'
					},
					{
						message: '请输入正确的价格',
						validator: moneyValid,
						trigger: 'blur'
					}
				],
				goodsinfo: [
					{
						required: true,
						message: '请输入配件品牌型号',
						trigger: 'blur'
					}
				],
				singleprice: [
					{
						required: true,
						message: '请输入配件单价',
						trigger: 'blur'
					},
					{
						message: '请输入正确的价格',
						validator: moneyValid,
						trigger: 'blur'
					}
				],
				goodscnt: [
					{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					}
				]
			},
			organizationType: hgetStorage('organizationType'),

			// 成本 关联 采购
			isEditCost1: false,
			purchaseAppTableData: [],
			pageIndexP: 1,
			pageSizeP: 10,
			totalP: 0,
			searchFormP: {},
			isEditCost: false,
			costForm: {
				cost: null,
				costinfo: null
			},
			rulesCost: {
				cost: [
					{ required: true, message: '请输入金额', trigger: 'blur' }
				],
				costinfo: [
					{ required: true, message: '请输入说明', trigger: 'blur' }
				]
			},
			percentsForm: {},
			isEditRealCost: false,
			costRealForm: {
				realprice: null,
				realpricememo: null
			},
			rulesRealCost: {
				realprice: [
					{ required: true, message: '请输入金额', trigger: 'blur' }
				],
				realpricememo: [
					{ required: true, message: '请输入说明', trigger: 'blur' }
				]
			},
			// 上传图片
			uploadLogoAction: null,
			logoFile: [],
			extFile: [],
			devExtImgUrlArr: [],
			isShowProcessDialog: false,
			formProcessData: {
				actionState: 3,
				devRepairId: null,
				title: null,
				actionDescribe: null,
				repairImgUrl: null
			},

			formProcessRule: {
				title: [
					{ required: true, message: '请输入标题', trigger: 'blur' }
				],
				actionDescribe: [
					{ required: true, message: '请输入描述', trigger: 'blur' }
				]
			}
		};
	},

	watch: {
		pageIndexP(newValue) {
			this.pageIndexP = newValue;
			this.purchaseApplicationList();
		},
		pageSizeP(newValue) {
			this.pageSizeP = newValue;
			this.purchaseApplicationList();
		},
		pageIndexS(newValue) {
			this.pageIndexS = newValue;
			this.saleApplicationList();
		},
		pageSizeS(newValue) {
			this.pageSizeS = newValue;
			this.saleApplicationList();
		}
	},

	computed: {
		totalMoney() {
			let totalMoneyTemp = 0;
			this.repairMoneyData.forEach(item => {
				totalMoneyTemp += item.price;
			});
			return totalMoneyTemp.toFixed(2);
		}
	},

	mounted() {
		console.log(this.uploadLogoAction);
		const { repairInfoId } = this.$route.query;
		this.repairInfoId = this.$route.query.repairInfoId;
		console.log(repairInfoId);
		this.getDetail();
		setTimeout(() => {
			this.imgUrl = this.$envConfig.imgHttpAdd;
			this.uploadLogoAction = `${this.$envConfig.btyong_5}devRepairInfoService/ImgUpdate`;
		}, 500);
		this.repairMoneyList();
	},

	methods: {
		isShowEditPaper() {
			this.$set(this.repairInfo, 'isEditPaper', true);
		},
		// 更新工单号
		async updatepaper(row) {
			const dataJson = { ...row };
			if (!dataJson.code) {
				this.$message.error('请先输入工单号');
				return;
			}
			const res = await updatepaperService(dataJson);
			this.getDetail();
		},

		// 编辑 已到场start 维修中ing 维修完成finish
		editRepairProgressS(row) {
			this.repairProgressForm = { ...row };
			this.repairProgressForm.repairImgUrlArr = [];
			console.log('/ 编辑 已到场start 维修中ing 维修完成finish');
			console.log(row);

			this.repairProgressForm.repairImgUrlArr = row.repairImgUrl
				.split(',')
				.map(item => {
					return { path: item };
				});
			console.log(this.repairProgressForm.repairImgUrlArr);
			const repairImgUrlArr = [
				...this.repairProgressForm.repairImgUrlArr
			];
			console.log('repairImgUrlArr');
			console.log(repairImgUrlArr);
			for (let index = 0; index < repairImgUrlArr.length; index++) {
				console.log(index);
				repairImgUrlArr[index].name = '图片';
				repairImgUrlArr[
					index
				].url = `${this.$envConfig.imgHttpAdd}repairprocessInfoservice/${repairImgUrlArr[index].path}`;
			}
			this.repairProgressForm.repairImgUrlArr = repairImgUrlArr;
			console.log(this.repairProgressForm.repairImgUrlArr);
			this.isShowRepairProgressS = true;
		},
		// 上传图片
		handlerExtSuccessS(response) {
			console.log(response);
			console.log(response.result);
			const res = {
				name: response.result.oldName,
				path: response.result.path,
				url: response.result.imgUrl
			};
			this.repairProgressForm.repairImgUrlArr.push(res);
			console.log(this.repairProgressForm.repairImgUrlArr);
		},
		addRepairProcessInfoS() {
			const repairImgUrlArr = [
				...this.repairProgressForm.repairImgUrlArr
			];
			const repairImgUrlArrStr = repairImgUrlArr.map(item => {
				return item.path;
			});

			this.repairProgressForm.repairImgUrl = repairImgUrlArrStr.join(',');

			delete this.repairProgressForm.actionTime;

			this.$refs.formProcessRef.validate(async valid => {
				if (valid) {
					if (!this.repairProgressForm.repairImgUrl) {
						this.$message.warning('请上传照片');
						return false;
					}
					const res = await updateRepairProcessInfoService(
						this.repairProgressForm
					);
					if (res == 1) {
						this.$message.success('操作成功！');
						this.cancelHandlerRepairProcessSIF();
						this.getDetail();
					} else {
						this.$message.error('操作失败！');
					}
				} else {
					console.log('表单验证失败!');
					return false;
				}
			});
		},
		handlerExtRemoveS(file, fileList) {
			const repairImgUrlArr = [...fileList];

			for (let index = 0; index < repairImgUrlArr.length; index++) {
				repairImgUrlArr[index].name =
					repairImgUrlArr[index].name || '图片';
				repairImgUrlArr[index].path =
					repairImgUrlArr[index].path ||
					repairImgUrlArr[index].response.result.path;
				// repairImgUrlArr[index].url = repairImgUrlArr[index].url;
			}
			this.repairProgressForm.repairImgUrlArr = repairImgUrlArr;
			console.log(repairImgUrlArr);
			// this.devExtImgUrlArr = [];
			// fileList.forEach(item => {
			// 	this.devExtImgUrlArr.push(
			// 		item.path || item.response.result.path
			// 	);
			// });
			// console.log(this.devExtImgUrlArr);
			// this.formProcessData.repairImgUrl = this.devExtImgUrlArr.join();
		},
		cancelHandlerRepairProcessSIF() {
			this.repairProgressForm = {};
			this.isShowRepairProgressS = false;
			this.isShowRepairProgressI = false;
			this.isShowRepairProgressF = false;
		},
		editRepairProgressI(row) {
			console.log(row);
		},
		editRepairProgressF(row) {
			console.log(row);
		},

		cancelHandlerRepairProcessTime() {
			this.processTimeObj = {};
			this.isShowProcessTimeDialog = false;
		},
		showProcessTimeDialog(obj) {
			this.processTimeObj = { ...obj };
			this.processTimeObj.repairid = this.repairInfoId;
			this.isShowProcessTimeDialog = true;
			console.log(this.processTimeObj);
		},

		// 修改时间 维修过程
		bindProcessTimeChange() {
			this.$refs.processTimeObjRef.validate(async valid => {
				if (valid) {
					console.log(this.processTimeObj);
					const res = await updaterepairtime1Service(
						this.processTimeObj
					);

					if (res == true || res == 'true') {
						this.$message.success('操作成功！');
						this.isShowProcessTimeDialog = false;

						this.getDetail();
					} else {
						this.$message.warning('请联系管理员！');
					}
				} else {
					console.log('表单验证失败!');
					return false;
				}
			});
		},

		// 增加维修过程
		handleAddRepairProcessInfo() {
			this.formProcessData = {};
			// this.repairInfoId
			this.isShowProcessDialog = true;
		},
		// 上传图片
		handlerExtSuccess(response) {
			this.devExtImgUrlArr.push(response.result.path);
			this.formProcessData.repairImgUrl = this.devExtImgUrlArr.join();
		},
		handlerExtRemove(file, fileList) {
			console.log(fileList);
			this.devExtImgUrlArr = [];
			fileList.forEach(item => {
				this.devExtImgUrlArr.push(
					item.path || item.response.result.path
				);
			});
			console.log(this.devExtImgUrlArr);
			this.formProcessData.repairImgUrl = this.devExtImgUrlArr.join();
		},
		// 提交 维修过程
		// 增加维修过程
		addRepairProcessInfo() {
			this.$refs.formAddProcessRef.validate(async valid => {
				if (valid) {
					if (!this.formProcessData.repairImgUrl) {
						this.$message.warning('请上传照片');
						return false;
					}
					this.formProcessData.devRepairId = this.repairInfoId;
					this.formProcessData.actionState = 3;
					const res = await addRepairProcessInfoService(
						this.formProcessData
					);
					console.log('res');
					console.log(res);
					this.isShowProcessDialog = false;

					this.getDetail();
				} else {
					console.log('表单验证失败!');
					return false;
				}
			});
		},
		// 取消维修过程
		cancelHandlerRepairProcessInfo() {
			// this.repairInfoId
			this.isShowProcessDialog = false;
			this.formProcessData = {};
		},

		async getDetail() {
			const json = { repairInfoId: this.repairInfoId };
			const res = await queryDevRepairInfoByIdProcessService(json);

			res.listpoNew = [];
			const isHave2 = res.listpo.some(
				item => item.actionState == 2 && item.repairImgUrl
			);
			const isHave4 = res.listpo.some(
				item => item.actionState == 4 && item.repairImgUrl
			);
			console.log(isHave2);
			console.log(isHave4);
			res.listpo?.forEach(item => {
				if (item.actionState == 1) {
					res.listpoNew[0] = item;
				}
				if (isHave2) {
					if (item.actionState == 2 && item.repairImgUrl) {
						res.listpoNew[1] = item;
					}
				} else if (item.actionState == 2) {
					res.listpoNew[1] = item;
				}
				if (isHave4) {
					if (item.actionState == 4 && item.repairImgUrl) {
						res.listpoNew[2] = item;
					}
				} else if (item.actionState == 4) {
					res.listpoNew[2] = item;
				}
			});

			this.repairInfo = res;
			this.percentsForm.percents = res.percents || [];
			this.percentsForm.devRepairId = res.devRepairId;
			console.log('this.percentsForm');
			console.log(this.percentsForm);
			if (this.repairInfo.realpricememo == '""') {
				this.repairInfo.realpricememo = null;
			}
			if (
				this.repairInfo.actionState >= 4 &&
				this.repairInfo.listpoNew[2].repairImgUrl.indexOf(',') !== -1
			) {
				console.log(this.repairInfo.listpoNew[2].repairImgUrl);
				this.repairInfo.listpoNew[2].repairImgUrlArr =
					this.repairInfo.listpoNew[2].repairImgUrl.split(',');
				console.log(this.repairInfo.listpoNew[2].repairImgUrl);
			} else if (
				this.repairInfo.actionState >= 4 &&
				this.repairInfo.listpoNew[2].repairImgUrl.indexOf(',') === -1
			) {
				this.repairInfo.listpoNew[2].repairImgUrlArr = [
					this.repairInfo.listpoNew[2].repairImgUrl
				];
			}
			// 维修完成照片
			if (this.repairInfo.listType3?.length > 0) {
				this.repairInfo.listType3.forEach((item, index) => {
					item.repairImgUrlArr = [];
					if (
						item.repairImgUrl.indexOf(',') == -1 &&
						item.repairImgUrl.length > 0
					) {
						item.repairImgUrlArr = [item.repairImgUrl];
					} else if (item.repairImgUrl.indexOf(',') != -1) {
						item.repairImgUrlArr = item.repairImgUrl.split(',');
					} else {
						item.repairImgUrlArr = [];
					}
				});
			}
		},
		async repairMoneyList() {
			const dataJson = {
				repairId: this.repairInfoId
			};
			const res = await repairMoneyListService(dataJson);
			console.log(res);
			this.repairMoneyData = res;
		},

		tableRowClassName({ row, rowIndex }) {
			if (rowIndex % 2 === 0) {
				return 'even-row';
			} else {
				return 'odd-row';
			}
		},

		// 成本 采购 显示
		addRepairCostAboutHandler() {
			this.purchaseApplicationList();
			this.isEditCost1 = true;
			this.isEditCost = false;
		},
		//  维修费用 显示 销售列表
		addRepairMoneyAboutHandler() {
			this.saleApplicationList();
			this.isShowRepairMoney1 = true;
		},

		// 获取销售列表
		async saleApplicationList() {
			const dataJson = {
				pageNum: this.pageIndexS,
				pageSize: this.pageSizeS,
				hid:
					this.repairInfo.affiliatedHospitalHd ||
					this.repairInfo.affiliatedHospitalId,
				...this.searchFormP
			};
			const res = await saleApplicationListService(dataJson);
			res.records.forEach(item => {
				if (item.items.length > 3) {
					item.showMaterialLength = 3;
				} else {
					item.showMaterialLength = 99;
				}
			});

			this.totalS = res.total;
			this.saleTableData = res.records;
		},
		// 获取采购列表
		async purchaseApplicationList() {
			const dataJson = {
				pageNum: this.pageIndexP,
				pageSize: this.pageSizeP,
				// this.repairInfoId,
				hid:
					this.repairInfo.affiliatedHospitalHd ||
					this.repairInfo.affiliatedHospitalId,
				...this.searchFormP
			};
			const res = await purchaseApplicationListService(dataJson);
			res.records.forEach(item => {
				if (item.items.length > 3) {
					item.showMaterialLength = 3;
				} else {
					item.showMaterialLength = 99;
				}
			});

			console.log('res 维修费用 采购列表');
			console.log(res);
			this.totalP = res.total;
			this.purchaseAppTableData = res.records;
		},

		showMoreMaterialP(row) {
			const table = this.purchaseAppTableData;
			table.forEach(item => {
				if (item.id == row.id) {
					if (item.showMaterialLength == 3) {
						item.showMaterialLength = 99;
					} else {
						item.showMaterialLength = 3;
					}
				}
			});
			this.purchaseAppTableData = table;
		},

		showMoreMaterialS(row) {
			const table = this.saleTableData;
			table.forEach(item => {
				if (item.id == row.id) {
					if (item.showMaterialLength == 3) {
						item.showMaterialLength = 99;
					} else {
						item.showMaterialLength = 3;
					}
				}
			});
			this.saleTableData = table;
		},
		async toGoodChooseP(row) {
			console.log('选中的row 成本 采购');
			console.log(row);
			console.log(this.repairInfo);
			const dataJson = {
				repairid: this.repairInfo.devRepairId,
				wmsbuyid: row.id
			};

			const res = await updatewmsinidService(dataJson);
			this.getDetail();
			// this.repairMoneyList();
			this.isEditCost1 = false;
		},
		async toGoodChooseS(row) {
			console.log('选中的row 维修费用 应收 销售');
			console.log(row);
			console.log(this.repairInfo.devRepairId);
			const dataJson = {
				repairid: this.repairInfo.devRepairId,
				wmssellid: row.id
			};

			const res = await updatewmsoutidService(dataJson);
			this.repairMoneyList();
			this.isShowRepairMoney1 = false;
		},

		// 维修费用 添加
		addRepairMoneyHandler() {
			this.isShowRepairMoney = true;
		},
		setsingleprice(val) {
			if (this.formData.isHave == 0) {
				this.formData.singleprice = val;
			}
		},
		getIsHave(val) {
			if (val == 0) {
				this.formData.goodsinfoOld = this.formData.goodsinfo;
				this.formData.singlepriceOld = this.formData.singleprice;
				this.formData.goodscntOld = this.formData.goodscnt;
				this.formData.goodsinfo = '无';
				this.formData.singleprice = this.formData.money;
				this.formData.goodscnt = 1;
			} else {
				this.formData.goodsinfo = this.formData.goodsinfoOld;
				this.formData.singleprice = this.formData.singlepriceOld;
				this.formData.goodscnt = this.formData.goodscntOld;
			}
			console.log('val');
			console.log(val);
		},
		addRepairMoney() {
			console.log(123);
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					const dataJson = {
						deviceid: this.repairInfo.devInfo.devInfoId,
						price: this.formData.money,
						repairid: this.repairInfoId,
						title: this.formData.name,
						goodsinfo: this.formData.goodsinfo,
						singleprice: this.formData.singleprice,
						goodscnt: this.formData.goodscnt
					};
					await repairMoneyAddService(dataJson);
					this.repairMoneyList();
					this.cancelHandler();
				}
			});
		},
		async editRepairMoney() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					const dataJson = {
						id: this.repairMoneyEditId,
						price: this.formData.money,
						title: this.formData.name,
						goodsinfo: this.formData.goodsinfo,
						singleprice: this.formData.singleprice,
						goodscnt: this.formData.goodscnt
					};
					await repairMoneyUpdateService(dataJson);
					this.repairMoneyList();
					this.cancelHandler();
				}
			});
		},
		cancelHandler() {
			this.formData.name = '';
			this.formData.money = '';
			this.formData.isHave = 1;
			this.formData.goodsinfo = null;
			this.formData.singleprice = null;
			this.formData.goodscnt = null;
			this.formData.goodsinfoOld = null;
			this.formData.singlepriceOld = null;
			this.formData.goodscntOld = null;
			this.isShowRepairMoney = false;
		},
		deleteHandler(item) {
			this.$confirm('确定需要删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.delId = item.id;
					this.delRepairMoney();
				})
				.catch(() => { });
		},
		async delRepairMoney() {
			// repairMoneyDelService
			const dataJson = {
				ids: this.delId
			};
			await repairMoneyDelService(dataJson);
			this.repairMoneyList();
		},
		async repariMoneyDetail(item) {
			const dataJson = {
				repairPriceId: item.id
			};
			const res = await repairMoneyDetailService(dataJson);
			this.isShowRepairMoney = true;
			this.repairMoneyEditId = res.id;
			this.formData.name = res.title;
			this.formData.money = res.price;
			if (res.goodsinfo == '无') {
				this.formData.isHave = 0;
			}
			this.formData.goodsinfo = res.goodsinfo;
			this.formData.singleprice = res.singleprice;
			this.formData.goodscnt = res.goodscnt;
		},
		addRepairCostHandler() {
			this.isEditCost = true;
			this.costForm = this.repairInfo;
		},
		async submitRepairCostHandler(formName) {
			this.$refs[formName].validate(async valid => {
				if (valid) {
					const res = await repairSetCostService(this.costForm);
					this.getDetail();
					res && this.$message.success('设置成功');
					!res && this.$message.success('设置失败，请联系管理员');
					this.isEditCost = false;
				} else {
					console.log('err!!');
					return false;
				}
			});
		},
		// 设置工作量
		async submitRepairPercentsHandler() {
			const workpercentsSum = this.percentsForm.percents.reduce(
				(p, c) => p + c.pct,
				0
			);

			if (workpercentsSum != 100) {
				this.$message.warning('工作量之和为100%');
				return false;
			}

			const res = await repairSetPercentsService(this.percentsForm);

			this.getDetail();
			res && this.$message.success('设置成功');
			!res && this.$message.success('设置失败，请联系管理员');
			this.isEditCost = false;
		},
		addRepairRealCostHandler() {
			this.isEditRealCost = true;
			this.costRealForm = this.repairInfo;
		},
		async submitRepairRealCostHandler(formName) {
			// if (!this.costRealForm.realpricememo) {
			// 	this.costRealForm.realpricememo = '""';
			// }
			this.$refs[formName].validate(async valid => {
				if (valid) {
					const res = await setRepairrealPriceService(
						this.costRealForm
					);
					this.getDetail();
					res && this.$message.success('设置成功');
					!res && this.$message.success('设置失败，请联系管理员');
					this.isEditRealCost = false;
				} else {
					console.log('err!!');
					return false;
				}
			});
		}
	},

	destroyed() { }
};
</script>
<style lang="less" scoped>
.repairDerailStep {
	width: 100%;
	color: #373737;
	font-weight: bold;
}

.repairDerailStep ul {
	display: flex;
	flex-wrap: wrap;
	flex: 1;
}

.repairDerailStep ul li {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-right: 5%;
	border-bottom: 1px dashed #cccccc;
	/* height: 30px; */
	line-height: 30px;
	margin-top: 20px;
}

.repairDerailStep ul li .title {
	color: #676968;
	font-weight: 700;
	word-break: keep-all;
}

.repairDerailStep ul li .content {
	color: #afaeb3;
	font-weight: normal;
	word-break: keep-all;
}

/* 维修单状态 */
/* 待接单 */
.equip_state {
	//   width: 101rpx;
	//   height: 42rpx;
	//   border: 1px solid #FF8431;
	color: #ff8431;
	//   text-align: center;
	//   line-height: 40rpx;
	//   border-radius: 6rpx;
}

/* 已响应 */
.equip_state1 {
	color: #0d69f5;
}

/* 已到场 */
.equip_state2 {
	color: #760cd2;
}

/* 维修中 */
.equip_state3 {
	color: #0ccbd2;
}

/* 待评价 */
.equip_state4 {
	color: #c1e986;
}

/* 已完成 */
.equip_state5 {
	color: #15bc84;
}

.p5b1 {
	padding: 5px;
	border: 1px solid #cccccc;
}

.p10b1 {
	padding: 10px;
	border: 1px solid #cccccc;
}

/deep/.el-step__description.is-success {
	color: #999;
}

/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}

.yellow {
	color: #ffd21e;
}

.f14 {
	font-size: 20px;
}

.money-box {}

.money-box .item {
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
	margin-bottom: 10px;
}

/deep/ .image-slot {
	line-height: 118px;
	text-align: center;
	font-weight: normal;
}

/deep/.even-row {}

/deep/.odd-row {
	background-color: rgba(64, 158, 255, 0.2);
}
</style>
